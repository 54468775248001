/* eslint max-len: ["error", { "code": 150 }]*/
import {mapGetters, mapActions, mapMutations} from 'vuex';
import ActiveAds from './ActiveAds/index.vue';
import ArchiveAds from './ArchiveAds/index.vue';
import Pagination from './../../../Common/Pagination/index.vue';
import openBillingWindow from '../../../../helpers/openBillingWindow';
import scrollToTop from '../../../../helpers/cabinetScroll';
import Notice from './Notice/index.vue';
import axios from "axios";

const EXPIRES = 2592000;

const {get, set} = require('../../../../helpers/cookie');

export default {
	data: function () {
		return {
			page: 1,
			pages: 0,
			limit: _TARGET_ === 'client' ? (Number(get('cabinet_ipp_manage')) || 10) : 10,
			type: this.$route.query.action || 'active',
			periodDefaultOptions: [1],
			totalObject: {},
			totalArray: [],
			totalSum: 0,
			totalSumFromTop: 0,
			paidPublications: 0,
			totalPublications: 0,
			availablePublicationsCount: 0,
			usedAvailablePublications: {},
			massTop: undefined,
			massSelectedDay: 4,
			massPublications: 0,
			checkBoxClearWall: false,
			checkBoxSelectAll: false,
			showArchiveBlock: false,
			valueArchiveSelect: false,
			same: {},
			alert: {},
			archiveRestoreErrorIds: [],
			activeCount: 0,
			archiveCount: 0,
			massSelectedDayInput: '',
			sendToActiveAds: false,
			calculateIt: false,
			checkedIdsFromURL: [],
			totalPublicationsPrice: 0,
			resetChild: false,
			virtualFreePublication: {},
			commonBlockTimeout: undefined,
			recalculateCommonBlock: false
		};
	},
	name: 'AdvertisementsManage',
	components: {
		'active-ads': ActiveAds,
		'archive-ads': ArchiveAds,
		'pagination': Pagination,
	  	'notice': Notice,
	},
	mounted() {
		this.fetchDefaultLevelInfo()
			.then((data) => {
				this.massSelectedDay = Number(data.defaultPeriod);
				this.massSelectedDayCalculate();
			});
	},
	created() {
		if (_TARGET_ === 'client') {
			if (this.$route.query.action) {
				this.checkedIdsFromURL = this.$route.query.ids.split(',');
				this.type = this.$route.query.action;
				this.page = Number(this.$route.query.page);
				this.limit = Number(this.$route.query.ipp);
				set('cabinet_ipp_manage', Number(this.$route.query.ipp), { expires: EXPIRES });
			}

			// чистим query
			let query = Object.assign({}, this.$route.query);
			delete query.ids;
			delete query.page;
			delete query.ipp;
			delete query.action;
			this.$router.replace({query});

			this.setAdvertisements({type: this.type});
			this.setPrices();
			this.massSelectedDayCalculate();
		}
		axios('/cabinet/api/left_bar/MyAdvertisements')
			.then((resp) => {
				this.pushCounters(resp.data);
			}).catch((e) => {
			console.error('/cabinet/api/left_bar/MyAdvertisements', e.message);
		});
		this.availablePublicationsCount = this._result(this.publications_, 'value.data');
		this.same = this.isAllParamsTheSame();
	},
	computed: {
		...mapGetters({
			advertisementsArray: 'cabinet/advertisements/manage/advertisements',
			counters: 'cabinet/leftBar/counters',
			additionalInfo: 'cabinet/advertisements/manage/additionalInfo',
			prices: 'cabinet/constants/prices',
			publications_: 'cabinet/balance/publications',
			account_: 'cabinet/balance/account',
			segment: 'cabinet/segment',
		}),
		_activeCount() {
			if (this.counters && (this.counters.MyAdvertisementsPublished || this.counters.UsedAutoAdvertisementsPublished)) {
				// eslint-disable-next-line max-len
				return (this._result(this.counters, 'MyAdvertisementsPublished.count') || this._result(this.counters, 'UsedAutoAdvertisementsPublished.count'));
			}
			return 0;
		},
		_archiveCount() {
			if (this.counters && this.counters.MyAdvertisementsArchive || this.counters.UsedAutoAdvertisementsArchive) {
				// eslint-disable-next-line max-len
				return (this._result(this.counters, 'MyAdvertisementsArchive.count') || this._result(this.counters, 'UsedAutoAdvertisementsArchive.count'));
			}
			return 0;
		},
		_awaitForPaymentCount() {
			if (this.counters && (this.counters.MyAdvertisementsUnpayed || this.counters.UsedAutoAdvertisementsUnpayed)) {
				return this._result(this, 'counters.MyAdvertisementsUnpayed.count') || this._result(this, 'counters.UsedAutoAdvertisementsUnpayed.count');
			}
			return 0;
		},
		_draftCount() {
			if (this.counters && (this.counters.MyAdvertisementsDraft || this.counters.UsedAutoAdvertisementsDraft)) {
				return this._result(this, 'counters.MyAdvertisementsDraft.count') || this._result(this, 'counters.UsedAutoAdvertisementsDraft.count');
			}
			return 0;
		},
		pageCount() {
			return Number(this[`_${this.type}Count`]);
		},
		loaderOnCommonBlock() {
			return Boolean(this.commonBlockTimeout);
		},
	},
	methods: {
		...mapActions({
			setAdvertisements: 'cabinet/advertisements/manage/advertisements',
			setAdditionalInfo: 'cabinet/advertisements/manage/additionalInfo',
			sendData: 'cabinet/advertisements/manage/sendData',
			sendDataRestore: 'cabinet/advertisements/manage/sendDataRestore',
			setPrices: 'cabinet/constants/prices',
			fetchDefaultLevelInfo: 'cabinet/advertisements/manage/fetchDefaultLevelInfo',
			pushCounters: 'cabinet/leftBar/pushCounters',
		}),
		checkRecalculateCommonBlock(flag) {
			if (flag) {
				if (this.commonBlockTimeout !== undefined) {
					clearTimeout(this.commonBlockTimeout);
				}
				this.commonBlockTimeout = setTimeout(() => {
					this.recalculateCommonBlock = flag;
					this.commonBlockTimeout = undefined;
				}, 2000);
			} else {
				this.commonBlockTimeout = undefined;
				this.recalculateCommonBlock = flag;
			}
		},
		/**
		 * Перезагрузка компоненты
		 */
		resetComponent() {
			this.totalObject = {};
			this.totalArray = [];
			this.totalSum = 0;
			this.totalSumFromTop = 0;
			this.paidPublications = 0;
			this.totalPublications = 0;
			this.usedAvailablePublications = {};
			this.massTop = undefined;
			this.massSelectedDay = 4;
			this.massPublications = 0;
			this.valueArchiveSelect = false;
			this.checkedIdsFromURL = [];
			this.checkBoxSelectAll = false;
			this.resetChild = true;
			this.virtualFreePublication = {};
			this.massSelectedDayInput = `${this.$tc('день', this.massSelectedDay)}`;
		},
		/**
		 * изменение массового ТОПа по действию blur
		 */
		onBlurMassTop() {
			this.sendToActiveAds = true;
			this.massTop = this.massTop > 3000 ? 3000 : this.massTop;
		},
		/**
		 * Хендлер з пагінації зміну сторінки
		 * @param page
		 */
		onChangePage(page) {
			this.resetComponent();
			this.page = page;
			this.setAdditionalInfo({
				type: this.type,
				ids: this.advertisementsArray[this.type].slice((this.page - 1) * this.limit, this.page * this.limit)
			});
		},
		/**
		 * Хендлер з пагінації зміну ліміну
		 * @param limit
		 */
		onChangeLimit(limit) {
			this.page = 1;
			this.limit = limit;
			set('cabinet_ipp_manage', limit, { expires: EXPIRES });

			this.resetComponent();
			this.setAdditionalInfo({
				type: this.type,
				ids: this.advertisementsArray[this.type].slice((this.page - 1) * this.limit, this.page * this.limit)
			});
		},
		/**
		 * Зміна типу (Активні/Архівні)
		 * @param type
		 */
		toggleMenu(type) {
			this.type = type;
			this.page = 1;
			this.alert = {};
			this.resetComponent();
			if (!this.advertisementsArray[type].length) {
				this.setAdvertisements({type});
			}
		},
		/**
		 * Селект "Все/Ниодного" на блоке мас. действиях архивных
		 */
		toggleArchiveSelect() {
			this.showArchiveBlock = !this.showArchiveBlock;
		},
		/**
		 * Функция которая пересчитывает суму в общем блоке, реализована через таймауты,
		 * что бы не вызывать ее каждый раз, для каждой компоненты, когда используються масовые действия
		 */
		calculateCommonBlock() {
			let virtualUseFreePublication = 0;
			this.totalPublicationsPrice = 0;
			this.totalSumFromTop = 0;
			this.totalPublications = 0;
			Object.keys(this.totalObject).forEach((item) => {
				let adv = this.totalObject[item];
				switch (true) {
					case adv.autoInfo.reasonId === 6:
						// Ничего не делаем потому что это восстановление с архива.
						break;
					case adv.publications === 0 && adv.top !== 0:
						// Не считаем публикации потому что пользователь выбрал только поднятия в топ
						break;
					case Boolean(adv.autoInfo.freePublicationOption):
						// Якщо сервіс цін каже шо це безкоштовна публікація
						this.virtualFreePublication[adv.id] = true;
						break;
					case Boolean(adv.autoInfo.publications) && virtualUseFreePublication < adv.autoInfo.publications:
						this.virtualFreePublication[adv.id] = true;
						virtualUseFreePublication++;
						break;
					default:
						this.virtualFreePublication[adv.id] = false;
						this.totalPublicationsPrice += Math.floor(adv.autoInfo?.basePrice || 0);
				}
				this.totalSumFromTop += Math.floor(adv.selectedDay * adv.top * adv.autoInfo?.levelPrice || 0);
			});

			this.totalPublications = Object.keys(this.totalObject).length;
			this.totalSum = this.totalPublicationsPrice + this.totalSumFromTop;

			this.same = this.isAllParamsTheSame();
		},
		/**
		 * Тігер в компоненті ActiveAds - який спрацьовує на зміни в формі одного активного оголошення
		 * Також нараховує кожному оголошенню, доступні публікації і не враховує їх в сумму, якщо такі публікації є
		 * @param data - дата з форми активного оголошенька
		 */
		onChangeAdsFrom(data) {
			if (!data.publications && !data.top && !data.republications) {
				delete this.totalObject[data.id];
				this.virtualFreePublication = {};
			} else {
				this.totalObject[data.id] = data;
			}
			this.checkRecalculateCommonBlock(true);

		},
		/**
		 * Функция которая проверяет совпадают ли в отмеченых объявлениях данные
		 * (кол-во желаемих публшикаций, ТОП, кол-во дней)
		 * в случае не совпадения данных в блоке масовых действий текст в инпуте будет сереньким (class='_grey')
		 * или если совпадают - черным
		 * @return {{top: boolean, selectedDay: boolean, publications: boolean}}
		 */
		isAllParamsTheSame() {
			let top = true;
			let publications = true;
			let selectedDay = true;
			let previousObject = {};
			for (let id in this.totalObject) {
				if (Object.keys(previousObject).length) {
					top = top && !(previousObject.top !== this.totalObject[id].top);
					publications = publications && !(previousObject.publications !== this.totalObject[id].publications);
					selectedDay = selectedDay && !(previousObject.selectedDay !== this.totalObject[id].selectedDay);
				}
				previousObject = this.totalObject[id];
			}

			return {publications, top, selectedDay};
		},
		/**
		 * наполнение форм даты и отправка на авто5
		 */
		toFormData() {
			let formData = new FormData();
			const formDataRestore = {
				advertisementId: [],
			};
			let sendToAuto5 = false;
			this.resetChild = false;
			let filling = new Promise((resolve, reject) => {
				if (Object.keys(this.totalObject).length) {
					formData.append('clearWall', Number(this.checkBoxClearWall));
					formData.append('referrer', 'groupActions');
					formData.append('device', this.isMobile ? 'mobile' : 'desktop');
					formData.append('sourceId', '4');
					Object.keys(this.totalObject).forEach((oneItem, index) => {
						formDataRestore.advertisementId.push(this.totalObject[oneItem].id);
						if (this.totalObject[oneItem].publications || this.totalObject[oneItem].top) {
							sendToAuto5 = true;
							formData.append(`advertisementId[${index}]`, this.totalObject[oneItem].id);
							formData.append(`publicationPeriod[${index}]`, this.totalObject[oneItem].publications);
						}
						if (this.totalObject[oneItem].top) {
							sendToAuto5 = true;
							formData.append(`level[${index}]`, this.totalObject[oneItem].top);
						}
						if (this.totalObject[oneItem].selectedDay) {
							sendToAuto5 = true;
							formData.append(`period[${index}]`, this.totalObject[oneItem].selectedDay);
						}
						if (index === Object.keys(this.totalObject).length - 1) {
							resolve();
						}
					});
				} else {
					reject();
				}
			});

			filling.then(() => {
				// якщо відновлення з архіву - то ще відправляємо запит на /user/restore
				if (this.type === 'archive') {
					this.sendDataRestore(formDataRestore).then((res) => {
						if(res && res?.error.length) {
							this.archiveRestoreErrorIds = res.error;
						}
					});
					if (!sendToAuto5) {
						// якщо тільки відновлення
						this.responseObserver({
							message: 'publications restored'
						});
						this.resetComponent();
					}
				}
				if (sendToAuto5) {
					// відправка formData на авто5
					this.sendData(formData).then((data) => {
						this.responseObserver(data);
						this.resetComponent();
					}).catch(() => {
						this.responseObserver();
						this.resetComponent();
					});
				}
			}).catch(() => {
				this.responseObserver({
					message: 'No publications selected'
				});
				this.resetComponent();
			});
		},
		/**
		 * Обработка ответа от биллинга, и сетап объекта алерта
		 * @param data
		 */
		responseObserver(data) {
			let billingWindowConfigs = `width=1000,height=780,left=${(screen.width / 2) - 980 / 2},top=80`;
			let descriptionHtml = '';
			let type = '';
			let title = '';
			let button;

			switch (this._result(data, 'message')) {
				case 'Order created':
					type = 'success';
					if (data.id > 0) {
						title = this.$t('Счет создан');
						switch (true) {
							case Boolean(this.totalPublicationsPrice && !this.totalSumFromTop):
								descriptionHtml = this.$t('Авто будет переопубликовано после подтверждения оплаты.');
								break;
							case Boolean(!this.totalPublicationsPrice && this.totalSumFromTop):
								descriptionHtml = this.$t('Ваш заказ на продвижение авто будет выполнен после подтверждения оплаты.');
								break;
							case Boolean(this.totalPublicationsPrice && this.totalSumFromTop):
								// eslint-disable-next-line max-len
								descriptionHtml = this.$t('Ваш заказ на продвижение и переопубликацию авто будет выполнен после подтверждения оплаты.');
								break;
						}
						button = {
							title: this.$t('Оплатить'),
							url: `https://oplaty.ria.com/order/${data.id}`,
							class: 'button--green'
						};
						openBillingWindow(data.id, '', billingWindowConfigs);
					} else if (data.spentPromoMoney > 0 || data.spentPublications > 0) {
						title = this.$t('Ваш заказ принят');
						descriptionHtml = `${this.$t('Для выполнения заказа с ваших счетов будет снято')}
								${data.spentPublications > 0 ? this.$tc('публикация', data.spentPublications) : ''}
								${(data.spentPromoMoney > 0 && data.spentPublications > 0) ? ` ${this.$t('и')}` : ''} 
								${data.spentPromoMoney > 0 ? data.spentPromoMoney + ' грн' : ''}`;
					}
					break;
				case 'publications restored':
					type = 'success';
					title = this.$t('Восстановление из архива');
					descriptionHtml = this.$t('Ваше объявление будет восстановлено в ближайшее время.');
					if (this.archiveRestoreErrorIds.length) {
						descriptionHtml = this.$t('Оголошення з');
						descriptionHtml += ` ID ${this.archiveRestoreErrorIds.join(', ')} `;
						descriptionHtml += this.$t('не вдалось відновити, спробуйте пізніше');
					}
					break;
				case 'AdvertisementId not specified':
					type = 'warning';
					descriptionHtml = this.$t('Выберите объявления.');
					break;
				case 'Cannot create order on billing':
					type = 'error';
					title = this.$t('Ошибка заказа');
					if (/unpaid orders/.test(data.error)) {
						descriptionHtml = this.$t('Вы превысили лимит неоплаченных счетов. Оплатите заказанные раньше услуги и попробуйте ещё раз.');
						button = {
							title: this.$t('Подробнее'),
							url: 'https://oplaty.ria.com/order/list-not-paid',
							class: 'button--transparent'
						};
					} else {
						descriptionHtml = this.$t('Попробуйте создать заказ немного позже.');
					}
					break;
				case 'No publications selected':
					type = 'warning';
					descriptionHtml = this.$t('Выберите объявления.');
					break;
				default:
					type = 'error';
					title = this.$t('Ошибка заказа');
					descriptionHtml = this.$t('Похоже, у нас технические неисправности :( Простите за неудобства, мы скоро всё починим.') + ' ' +
						this.$t('Попробуйте создать заказ немного позже.');
					break;
			}
			scrollToTop(280);
			this.alert = {
				type,
				title,
				descriptionHtml,
				button,
			};
		},
		massSelectedDayCalculate(action) {
			let recognizedInput;
			switch (action) {
				case 'increment':
					this.massSelectedDay++;
					break;
				case 'decrement':
					this.massSelectedDay--;
					break;
				case 'parse':
					// eslint-disable-next-line no-case-declarations
					recognizedInput = this.massSelectedDayInput.match(/([0-9])/ig);
					if (recognizedInput) {
						this.massSelectedDay = recognizedInput.join('');
					}
					break;
				default:
					break;
			}
			this.massSelectedDayValidation();
			this.massSelectedDayInput = `${this.$tc('день', this.massSelectedDay)}`;
		},
		topCalculate(action) {
			this.massTop = this.massTop ? this.massTop : 0;
			this.calculateIt = true;
			switch (action) {
				case 'increment':
					this.massTop++;
					break;
				case 'decrement':
					this.massTop--;
					this.massTop = this.massTop < 0 ? 0 : this.massTop;
					break;
			}
		},
		massSelectedDayValidation() {
			if (this.massSelectedDay < 1) {
				this.massSelectedDay = 1;
			} else if (this.massSelectedDay > 60) {
				this.massSelectedDay = 60;
			}
		}
	},
	watch: {
		recalculateCommonBlock() {
			this.calculateCommonBlock();
			this.recalculateCommonBlock = false;
		},
		'advertisementsArray.active'(newValue) {
			this.setAdditionalInfo({
				type: this.type,
				ids: newValue.slice(this.limit * (this.page - 1), this.page * this.limit)
			});
		},
		'advertisementsArray.archive'(newValue) {
			this.setAdditionalInfo({
				type: this.type,
				ids: newValue.slice(this.limit * (this.page - 1), this.page * this.limit)
			});
		},
		'advertisementsArray.awaitForPayment'(newValue) {
			this.setAdditionalInfo({
				type: this.type,
				ids: newValue.slice(this.limit * (this.page - 1), this.page * this.limit)
			});
		},
		'advertisementsArray.draft'(newValue) {
			this.setAdditionalInfo({
				type: this.type,
				ids: newValue.slice(this.limit * (this.page - 1), this.page * this.limit)
			});
		},
		massTop() {
			if (this.calculateIt) {
				this.sendToActiveAds = true;
				this.calculateIt = false;
			} else {
				this.sendToActiveAds = false;
			}
		},
	},
	beforeDestroy() {
		this.resetComponent();
	},
	i18n: {
		messages: {
			uk: {
				'день': '0 днів | {count} день | {count} дні | {count} днів',
				'Управление объявлениями': 'Керування оголошеннями',
				'Черновики': 'Чернетки',
				'Ожидают оплаты': 'Очікують оплати',
				'Опубликованные': 'Опубліковані',
				'Архивные': 'Архівні',
				'Выбрать все': 'Вибрати усі',
				'Удалить торги, обмены и комментарии': 'Видалити торги, обміни і коментарі',
				'Период публикации': 'Період публікації',
				'дней': 'днів',
				'Уровень': 'Рівень',
				'Период': 'Період',
				'Все': 'Усі',
				'Ни одного': 'Жодного',
				'Доступно публикаций': 'Доступно публікацій',
				'публикация выбрана': 'публікацій обрано | публікація обрана | публікації обрано | публікацій обрано',
				'На вашем Личном счету': 'На вашому Особистому рахунку',
				'уровней на суму': 'рівнів на суму',
				'Всего к оплате': 'Всьго до сплати',
				'Перейти к оплате': 'Перейти до оплати',
				'Счет создан': 'Рахунок створено',
				'Подробнее': 'Детальніше',
				'Оплатить': 'Оплатити',
				'Ваш заказ принят': 'Ваше замовлення прийнято',
				'Для выполнения заказа с ваших счетов будет снято': 'Для виконання замовлення з ваших рахунків буде знято',
				'публикация': '0 публікацій | {count} публікація | {count} публікації | {count} публікацій',
				'и': 'та',
				'Восстановление из архива': 'Відновлення з архіву',
				'Ваше объявление будет восстановлено в ближайшее время.': 'Ваше оголошення буде відновлено найближчим часом.',
				'Выберите объявления.': 'Виберіть оголошення.',
				'Ошибка заказа': 'Помилка замовлення',
				'Вы превысили лимит неоплаченных счетов. Оплатите заказанные раньше услуги и попробуйте ещё раз.': 'Ви перевищили ліміт неоплачених рахунків. Оплатіть замовлені  раніше послуги та спробуйте ще раз.',
				'Попробуйте создать заказ немного позже.': 'Спробуйте створити замовлення трохи пізніше.',
				'Похоже, у нас технические неисправности :( Простите за неудобства, мы скоро всё починим.': 'Схоже, у нас технічні несправності :( Вибачте за незручності, ми скоро все полагодимо.',
				'У Вас нет объявлений': 'У Вас немає оголошень',
				'Авто будет переопубликовано после подтверждения оплаты.': 'Авто буде переопубліковано після підтвердження оплати.',
				'Ваш заказ на продвижение авто будет выполнен после подтверждения оплаты.': 'Ваше замовлення на просування авто буде виконано після підтвердження оплати.',
				'Ваш заказ на продвижение и переопубликацию авто будет выполнен после подтверждения оплаты.': 'Ваше замовлення на просування та переопублікацію авто буде виконано після підтвердження оплати.',
				'Оголошення з': 'Оголошення з',
				'не вдалось відновити, спробуйте пізніше': 'не вдалось відновити, спробуйте пізніше',
			},
			ru: {
				'день': '0 дней | {count} день | {count} дня | {count} дней',
				'Управление объявлениями': 'Управление объявлениями',
				'Опубликованные': 'Опубликованные',
				'Архивные': 'Архивные',
				'Черновики': 'Черновики',
				'Ожидают оплаты': 'Ожидают оплаты',
				'Выбрать все': 'Выбрать все',
				'Удалить торги, обмены и комментарии': 'Удалить торги, обмены и комментарии',
				'Период публикации': 'Период публикации',
				'дней': 'дней',
				'Уровень': 'Уровень',
				'Период': 'Период',
				'Все': 'Все',
				'Ни одного': 'Ни одного',
				'Доступно публикаций': 'Доступно публикаций',
				'публикация выбрана': 'публикаций выбрана | публикация выбрана | публикации выбрано | публикаций выбрано',
				'На вашем Личном счету': 'На вашем Личном счету',
				'уровней на суму': 'уровней на суму',
				'Всего к оплате': 'Всего к оплате',
				'Перейти к оплате': 'Перейти к оплате',
				'Счет создан': 'Счет создан',
				'Подробнее': 'Подробнее',
				'Оплатить': 'Оплатить',
				'Ваш заказ принят': 'Ваш заказ принят',
				'Для выполнения заказа с ваших счетов будет снято': 'Для выполнения заказа с ваших счетов будет снято',
				'публикация': '0 публикаций | {count} публикация | {count} публикации | {count} публикаций',
				'и': 'и',
				'Восстановление из архива': 'Восстановление из архива',
				'Ваше объявление будет восстановлено в ближайшее время.': 'Ваше объявление будет восстановлено в ближайшее время.',
				'Выберите объявления.': 'Выберите объявления.',
				'Ошибка заказа': 'Ошибка заказа',
				'Вы превысили лимит неоплаченных счетов. Оплатите заказанные раньше услуги и попробуйте ещё раз.': 'Вы превысили лимит неоплаченных счетов. Оплатите заказанные раньше услуги и попробуйте ещё раз.',
				'Попробуйте создать заказ немного позже.': 'Попробуйте создать заказ немного позже.',
				'Похоже, у нас технические неисправности :( Простите за неудобства, мы скоро всё починим.': 'Похоже, у нас технические неисправности :( Простите за неудобства, мы скоро всё починим.',
				'У Вас нет объявлений': 'У Вас нет объявлений',
				'Авто будет переопубликовано после подтверждения оплаты.': 'Авто будет переопубликовано после подтверждения оплаты.',
				'Ваш заказ на продвижение авто будет выполнен после подтверждения оплаты.': 'Ваш заказ на продвижение авто будет выполнен после подтверждения оплаты.',
				'Ваш заказ на продвижение и переопубликацию авто будет выполнен после подтверждения оплаты.': 'Ваш заказ на продвижение и переопубликацию авто будет выполнен после подтверждения оплаты.',
				'Оголошення з': 'Объявления с',
				'не вдалось відновити, спробуйте пізніше': 'не удалось восстановить, попробуйте позже',
			}
		}
	}
};
